import { createRouter, createWebHistory} from "vue-router";

// const index = () => import("../pages/Index")
const lbt = () => import("../pages/Lbt")
// const product = () => import("../pages/Product")
const market = () => import("../pages/Market")
const worldTour = () => import("../pages/WorldTour")
const avatarid = () => import("../pages/AvatarId")
const disclaimer = () => import("../pages/Disclaimer")
// const ecosystem = () => import("../pages/Ecosystem")
const upgrade = () => import("../pages/Upgrade")
const journey = () => import("../pages/Journey")
const index2 = () => import("../pages/Index2")
const product2 = () => import("../pages/Product2")
const product3 = () => import("../pages/Product3")
const ecosystem2 = () => import("../pages/Ecosystem2")
const notFound = () => import("../pages/404")

const routes = [
  {
    path: '/',
    name: "index",
    component: index2
  },
  {
    path: '/index2',
    name: "index2",
    component: index2
  },
  {
    path: '/product2',
    name: "product2",
    component: product2
  },
  {
    path: '/product3',
    name: "product3",
    component: product3
  },
  {
    path: '/ecosystem',
    name: "ecosystem",
    component: ecosystem2
  },
  {
    path: '/lbt',
    name: "lbt",
    component: lbt,
    meta: {
      title: 'LBT',
    }
  },
  {
    path: '/market',
    name: "market",
    component: market,
    meta: {
      title: 'Market',
    }
  },
  {
    path: '/product',
    name: "product",
    component: product3,
    meta: {
      title: 'Product',
    }
  },
  {
    path: '/world_tour',
    name: "world tour",
    component: worldTour,
    meta: {
      title: 'World Tour',
    }
  },
  {
    path: '/avatarid',
    name: "avatarid",
    component: avatarid,
    meta: {
      title: 'AvatarId',
    }
  },
  {
    path: '/disclaimer',
    name: "disclaimer",
    component: disclaimer,
    meta: {
      title: 'Disclaimer',
    }
  },
  {
    path: '/upgrade',
    name: "upgrade",
    component: upgrade,
    meta: {
      title: 'Upgrade',
    }
  },
  {
    path: '/journey',
    name: "lifeform journey",
    component: journey,
    meta: {
      title: 'Lifeform Journey',
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'notFound', 
    component: notFound,
    meta: {
      title: 'NOT FOUND',
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass:'active-link',
  routes: routes
})

export default router;