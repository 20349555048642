<template>
  <div class="page-container">
    <router-view></router-view>
  </div>
</template>

<script>
import { watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'App',

  setup() {
    const route = useRoute();

    const initPage = () => {
      //window.history.replaceState(null, null, window.location.href);

      if(route.meta && route.meta.title) {
        document.title = route.meta.title + " - Lifeform - Redefine Digital Citizen Identify";
      }
      else {
        document.title = "Lifeform - Redefine Digital Citizen Identify";
      }
    };

    onMounted(() => {
      setTimeout(() => {
        initPage();
      }, 100);
    });

    watch(() => route.path, () => {
      if(route.path != '/product' || !(route.query && route.query.tgt)) {
        window.scroll(0, 0);
      }
      initPage();
    });
  }
}
</script>

<style scoped>
body {
  cursor: auto;
}

.page-container {
  height: 100%;
  overflow: auto;
}
</style>
