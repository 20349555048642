import { createApp } from 'vue';
import App from './App.vue';
import router from './route';
import { vfmPlugin } from "vue-final-modal";
import Toast from "vue-toastification";

import "vue-toastification/dist/index.css";
import './assets/css/bootstrap.css';
import './assets/css/base.css';

const toastOptions = {
  containerClassName: "custom-toast-container",
  position: "top-center",
  timeout: 3000,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
};

const app = createApp(App);
app.use(router);
app.use(vfmPlugin);
app.use(Toast, toastOptions);
app.mount('#app');
